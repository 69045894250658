export default {
  h1: {
    fontSize: 6,
    color: 'primary',
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    mt: 4,
    mb: 2,
  },
  h2: {
    marginTop: 2,
    color: 'primary',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: 5,
    mt: 4,
    mb: 2,
  },
  h3: {
    color: 'text',
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    fontSize: 3,
    mt: 2,
    mb: 2,
  },
  h4: {
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'body',
    fontSize: 2,
    mt: 2,
    mb: 2,
  },
  h5: {
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: 1,
  },
  h6: {
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: 0,
  },
  bookTitle: {
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'text',
    maxHeight: '3em',
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    fontSize: 0,
  },
  author: {
    textOverflow: 'ellipsis',
    color: 'muted',
    fontFamily: 'body',
    lineHeight: 1.25,
    fontWeight: 'body',
    fontSize: 0,
    maxHeight: '2.5em',
    overflow: 'hidden',
  },
  p: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
  },
  a: {
    color: 'primary',
    textDecoration: 'none',
  },
};
